import { Accordion, Card, Grid, Typography } from '@platform-ui/design-system';
import React, { Dispatch, useEffect } from 'react';
import BasicTemplate from '../../../../BasicTemplate/BasicTemplate';
import BasicTemplateContent from '../../../../BasicTemplate/BasicTemplateContent';
import Divider from '@mui/material/Divider';
import { HeaderTitle } from './HeaderTitle';
import { HeaderAction } from './HeaderAction';
import './Authentication.css';
import { Action, useStoreContext } from '../../../../Store';
import { IADState } from '../../../../IntegrationApps/IntegrationAppDetails/state';
import Connect from '../../../../Connect/Connect';
import { WDB_ADD_SOLUTION_INSTANCE, WDB_LOAD_SOLUTION_INSTANCES, WDB_PROVISION_SOLUTION_INSTANCE_ERROR } from '../action_types';

export const Authentication = ({
  tabName = 'authentication',
  locale,
  fieldGroups,
  lastUpdatedBy,
  lastUpdatedDate
}) => {
  const { state, dispatch } = useStoreContext() as { state: IADState, dispatch: Dispatch<Action> };
  const firstFieldGroup = fieldGroups?.slice(0, 1) || [];
  const otherFieldGroups = fieldGroups?.slice(1) || [];

  const title = firstFieldGroup[0]?.titleTranslations?.[locale];
  const collapsible = firstFieldGroup[0]?.collapsible;
  const hidden = firstFieldGroup[0]?.hidden;
  const cardContent = <BasicTemplateContent fieldGroups={firstFieldGroup} locale={locale} tabName={tabName} />;
  const subheader = 'Last Updated By: ' + `${lastUpdatedBy ? lastUpdatedBy : 'N/A'}` + ' | Last Updated Date: ' + `${lastUpdatedDate ? lastUpdatedDate : 'N/A'}`;
  const cardBody = hidden ? null : collapsible
    ? <Accordion titleBar header={<Typography children={title} variant='h6' />} body={cardContent} elevation={0} variant='fullWidth' />
    : cardContent;
  
  const connect: Connect = (window as any).connect;
  const isLocal = window.location.host.includes('localhost');
  const workflowProxyPath = `${isLocal ? 'http://localhost:8080' : ''}/platform/gateway-proxy-v2`;
  const workflowName = state.workflow_template?.workflow_definition?.name || 'z-IntegrationHub-Workday-Billing';
  const templateWorkflowVersion = state.workflow_template?.workflow?.version || '0.0.1';

  const retrieveWorkdayWF = async () => {
    const response = await Connect.proxyCall(
      `${workflowProxyPath}/workflows?name=${workflowName}`,
      'GET',
      undefined,
      { 'Zuora-Tenant-Id': (connect.tenant as any).tenant_id, 'Scope': 'Internal' }
    );

    return response;
  };

  const createWorkdayWF = async () => {
    const response = await Connect.proxyCall(
      `${workflowProxyPath}/workflows/import?activate=true`,
      'POST',
      state.workflow_template,
      {
        'Zuora-Tenant-Id': (connect.tenant as any).tenant_id,
        'Content-Type': 'application/json',
        'Scope': 'Internal'
      }
    );
    return response;
  };

  const updateWorkdayWF = async (id: number, version: string) => {
    const response = await Connect.proxyCall(
      `${workflowProxyPath}/workflows/${id}/versions/import?version=${version}&activate=true`,
      'POST',
      state.workflow_template,
      {
        'Zuora-Tenant-Id': (connect.tenant as any).tenant_id,
        'Content-Type': 'application/json',
        'Scope': 'Internal'
      }
    );
    return response;
  }

  const provisionWorkdayWF = async () => {
    try {
      let instance;
      let response = await retrieveWorkdayWF();
      if (!response.ok) throw Error(response.statusText);
      const { data: instances } = await response.json();
      if (Array.isArray(instances) && instances.length === 0) {
        response = await createWorkdayWF();
        if (!response.ok) throw Error(response.statusText);
        const instance = await response.json();
        if (instance.errors) throw Error(instance.errors?.[0]);
        dispatch({
          type: WDB_ADD_SOLUTION_INSTANCE,
          payload: {
            id: instance.id,
            solution_instance_name: instance.name,
            created_at: instance.createdAt,
            active_version_id: instance.active_version?.id || instance.id,
            scheduled_trigger: instance.scheduledTrigger,
            interval: instance.interval,
            timezone: instance.timezone
          }
        });
      } else if (Array.isArray(instances) && (instance = instances.find(instance => instance.name === workflowName)) && (instance.active_version?.version !== templateWorkflowVersion)) {
        response = await updateWorkdayWF(instance.id, templateWorkflowVersion);
        if (!response.ok) throw Error(response.statusText);
        instance = await response.json();
        if (instance.errors) throw Error(instance.errors?.[0]);
        dispatch({
          type: WDB_ADD_SOLUTION_INSTANCE,
          payload: {
            id: instance.id,
            solution_instance_name: instance.name,
            created_at: instance.createdAt,
            active_version_id: instance.active_version?.id || instance.id,
            scheduled_trigger: instance.scheduledTrigger,
            interval: instance.interval,
            timezone: instance.timezone
          }
        });
      } else {
        const rows = instances.map(instance => ({
          id: instance.id,
          solution_instance_name: instance.name,
          created_at: instance.createdAt,
          active_version_id: instance.active_version?.id || instance.id,
          scheduled_trigger: instance.scheduledTrigger,
          interval: instance.interval,
          timezone: instance.timezone
        }));
  
        dispatch({
          type: WDB_LOAD_SOLUTION_INSTANCES,
          payload: rows
        });
      }
    } catch (error) {
      Connect.log(error);
      dispatch({ type: WDB_PROVISION_SOLUTION_INSTANCE_ERROR });
    }
  };

  useEffect(() => {
    provisionWorkdayWF();
  }, []);
  
  return (
    <>
      <Card
        id={`${tabName}-id`}
        e2e={`${tabName}-template`}
        header={
          <>
            <Grid container alignItems='center'>
              <HeaderTitle
                header='Status'
                subheader={subheader}
                showTestConnection={true}
                tabName={tabName}
              />
              <Divider style={{marginTop: '15px'}} orientation='vertical' flexItem />
              <HeaderAction />
            </Grid>
            <Divider style={{marginTop: '20px'}} />
          </>
        }
        body={cardBody}
      />
      {
        otherFieldGroups.map((fieldGroup, idx) => (
          <div style={{ marginTop: '10px' }}>
            <BasicTemplate
              id={`basic-template-field-group-${idx}`}
              showHeader={false}
              fieldGroups={[fieldGroup]}
              locale={locale}
              tabName={tabName}
            /> 
          </div>
        ))
      }
    </>
  );
};
import { State } from '../../Store';

export interface IADState extends State {
  modified?: boolean;
  createOrUpdate?: string;
  lastUpdatedBy?: string;
  updatedAt?: string;
  settingsUrl?: string;
  active?: boolean;
  originalAuthentication?: any;
  originalSettings?: any;
  production?: boolean;
  saved?: boolean;
  emailNotificationsModified?: boolean;
  originalEmailNotifications?: any;
  enablePrivateWorkflow?: boolean;
  enableTaskSummary?: boolean;
  inbound?: boolean;
  currentUser?: string;
  tabDetails?: any[];
  credsModified?: boolean;
  reloadFieldMappings?: boolean;
  solutionInstanceUpdated?: boolean;
  provisionSolutionInstanceError?: boolean;
  netsuiteAdmin?: boolean;
  netsuiteNewLayout?: boolean;
  external_id?: string;
  workflow_template?: any;
  workflow_template_id?: number;
  workflow_template_name?: string;
  workflow_auto_refresh_frequency?: number;
  windmillPath?: string;
  workday_mappings?: any[];
  default_mappings?: any;
  update_workflow?: boolean;
  envRestEndpoint?: string;
}

export const initialState: IADState = {
  settingsHash: {}
};